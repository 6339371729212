<template>
    <b-card>
        <b-row class="mb-2 d-flex justify-content-end mr-1">
<!--            <b-button size="sm" @click="showModalFile" variant="primary"  class="mr-2" v-if="hasBulkLoadPermission">
                <font-awesome-icon icon="file"></font-awesome-icon> Bulk load
            </b-button>-->
            <b-button variant="primary" :to="{ name: 'ProductionLineNew' }" v-if="hasPermission">
                <font-awesome-icon icon="plus"></font-awesome-icon> Add production line
            </b-button>
        </b-row>

        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Show" append="records">
                    <b-form-select v-model="productionLines.itemsPerPage" id="perPageSelect" size="sm" :options="productionLines.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col>
                <div class="mt-3 d-flex justify-content-end">
                    <b-button-group>
                        <b-button :variant="colorUnassigned" @click="changeProductionLineStatus(1)">Unassigned</b-button>
                        <b-button :variant="colorAssigned" @click="changeProductionLineStatus(2)">Assigned</b-button>
                        <b-button :variant="colorFinished" @click="changeProductionLineStatus(3)">Finished</b-button>
                        <b-button :variant="colorCancelled" @click="changeProductionLineStatus(4)">Cancelled</b-button>
                    </b-button-group>
                </div>
            </b-col>
        </b-row>
        <b-table :items="loadData" :fields="productionLines.fields" :filter="productionLines.filter" :current-page="productionLines.currentPage"
                 :per-page="productionLines.itemsPerPage" empty-text="No records" empty-filtered-text="No results"
                 striped bordered hover small show-empty :responsive="true" :sort-by="productionLines.sortBy" :sort-desc="productionLines.sortDesc"
                 ref="productionLineTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in productionLines.fields" :key="field.key"
                          :class="productionLines.filters[index] && productionLines.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="productionLines.filters[index]">
                            <b-col v-if="productionLines.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="productionLines.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(processStatus)="row">
                <div class="text-center">
                    <span class="label label-primary mr-2" style="width: 10px" v-b-tooltip.hover :title="row.item.processStatus">
                        {{ row.item.processStatus.charAt(0).toUpperCase() }}
                    </span>
                </div>
            </template>

            <template #cell(varietyName)="row">
                <div class="text-center">
                    <b-img class="img-fluid mb-1" style="width: 31px;" v-if="row.item.figureGraphic" :src="urlImg + row.item.fileId"
                           @click="showImg(index, row.item)" :alt="row.item.varietyName"></b-img>
                    <p>
                        <router-link :to="{ name: 'ProductionLineShow', params: { id: row.item.id, variety: row.item.varietyName, tag: row.item.tag  }}">{{ row.item.varietyName }}{{row.item.tag ? '-'+row.item.tag : ''}}</router-link>
                    </p>
                    <p class="no-margins" v-if="row.item.figureGraphic" >{{ row.item.figureName }}</p>
                </div>
            </template>

            <template #cell(compartmentName)="row">
				<router-link v-if="row.item.compartmentName" :to="{ name: 'CompartmentsShow', params: { id: row.item.compartmentId }}" target="_blank">
                    {{ row.item.compartmentName }}
                </router-link>
            </template>

            <template #cell(orderDate)="row">
                    {{ row.item.orderDate }}
            </template>

            <template #cell(createdDate)="row">
                    {{ row.item.createdDate }}
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'ProductionLineShow', params: { id: row.item.id, variety: row.item.varietyName, tag: row.item.tag }}" v-b-tooltip.hover
                              title="Detail" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" :to="{ name: 'ProductionLineEdit', params: { id: row.item.id, variety: row.item.varietyName, tag: row.item.tag  }}" v-b-tooltip.hover
                              title="Edit" variant="outline-primary" v-if="hasPermission">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" v-b-tooltip.hover title="Split"
                              variant="outline-primary" @click="showDivideModal(row.item)" v-if="!row.item.parentId && hasPermission">
                        <font-awesome-icon icon="divide"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click="deleteProductionLine(row.item)" v-b-tooltip.hover title="Delete" variant="outline-primary" v-if="hasPermission">
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Show {{ start }} to {{ end }} of {{ productionLines.totalRows }} record{{ productionLines.totalRows !== 1 ? 's' : ''}}
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="productionLines.currentPage" :total-rows="productionLines.totalRows"
                                      :per-page="productionLines.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>

        <ValidationObserver v-slot="{ passes }">
            <b-modal id="order-files-modal" title="Upload order file" size="lg" centered no-close-on-backdrop no-close-on-esc @close="clearFileData">
                <b-form @submit.prevent="passes(saveOrderFile)" id="files-form">
                    <b-row>
                        <b-col md="12" sm="12" class="mb-5">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Order file" id="inputOrderFile" style="margin-top:0;" :invalid-feedback="errors[0]">
                                    <b-form-file v-model="orderFile" type="file" accept=".xlsx, .xls" size="lg"
                                                 :state="errors[0] ? false : null">
                                    </b-form-file>
                                    <div class="mt-3"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form>
                <template #modal-footer>
                    <div class="text-right">
                        <b-button style="background-color: #a0a0a0; border: 0;" @click="clearFileData" class="mr-2"> Cancel</b-button>
                        <b-button type="submit" form="files-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
                    </div>
                </template>
            </b-modal>
        </ValidationObserver>

        <b-modal id="divide-production-line-modal" v-if="productionLineSelected" :title="`Production line ${productionLineSelected.varietyName} split`" size="md" centered no-close-on-backdrop no-close-on-esc>
            <ValidationObserver v-slot="{ passes }" ref="formDivideProductionLine">
                <h5 class="text-right mt-2 mb-2"><strong>Original order: </strong>{{ Number(productionLineSelected.originalOrderKg).toFixed(2) }} Kg.</h5>
                <h5 class="text-right mt-2 mb-5"><strong>Remaining order: </strong>{{ Number(calculateRemainingKg).toFixed(2) }} Kg.</h5>
                <b-form @submit.prevent="passes(divideProductionLine)" id="divide-form">
                    <b-table-simple class="mt-3" bordered style="overflow: visible">
                        <b-thead>
                            <b-tr>
                                <b-th class="text-center" style="width: 20%">Production line</b-th>
                                <b-th class="text-center" style="width: 20%">Kg.</b-th>
                                <b-th class="text-center" style="width: 20%" v-if="hasPermission">Actions</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-if="hasPermission">
                                <b-td class="text-center" style="vertical-align: middle">
                                    {{ productionLineSelected.varietyName + '-'+productionLineSelected.nextTag }}
                                </b-td>
                                <b-td class="text-right">
                                    <ValidationProvider :rules="'required|number|min_value:0.01|maxKg:' + productionLineSelected.orderKg" v-slot="{ errors }">
                                        <b-form-group class="d-flex align-items-center"
                                                      label="" label-for="input-kg" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="divideKg" autocomplete="false" size="lg" id="input-kg"
                                                          :state="errors[0] ? false : null">
                                            </b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-td>
                                <b-td class="text-center" style="vertical-align: middle;" v-if="hasPermission">
                                    <b-button type="submit" form="divide-form" variant="outline-primary" v-b-tooltip.hover.bottom title="Save"><font-awesome-icon icon="save"></font-awesome-icon></b-button>
                                </b-td>
                            </b-tr>
                            <template v-if="productionLineSelected.children.length > 0">
                                <b-tr v-for="(item, index) in productionLineSelected.children" :key="index">
                                    <template>
                                        <b-td class="text-center" style="vertical-align: middle;">{{ item.variety }}-{{item.tag}}</b-td>
                                        <b-td class="text-right" style="vertical-align: middle">{{ Number(item.orderKg).toFixed(2) }} Kg.</b-td>
                                        <b-td v-if="hasPermission"></b-td>
                                    </template>
                                </b-tr>
                            </template>
                            <template v-else-if="productionLineSelected.children.length === 0 && !hasPermission">
                                <b-tr>
                                    <b-td colspan="3" class="text-center">No records</b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                    </b-table-simple>
                </b-form>
            </ValidationObserver>
            <template #modal-footer>
                <div class="text-right">
                    <b-button style="background-color: #a0a0a0; border: 0;" @click="$bvModal.hide('divide-production-line-modal')" class="mr-2"> Close</b-button>
                </div>
            </template>
        </b-modal>

        <b-modal id="duplicated-production-lines-modal" title="Duplicated production lines" size="md" centered no-close-on-backdrop no-close-on-esc hide-header-close>
            <p class="mb-4">The following production lines could not be created. Please create them manually.</p>
            <b-table hover :items="duplicatedProductionLines"></b-table>
            <template #modal-footer>
                <div class="text-right">
                    <b-button @click="confirmCloseDuplicatedModal" variant="primary">Close</b-button>
                </div>
            </template>
        </b-modal>

        <b-modal id="missing-headers-modal" title="Missing headers" size="md" centered no-close-on-backdrop no-close-on-esc hide-header-close>
            <p class="mb-4">The following headers were not found in the file:</p>
            <template v-for="(header, key) in missingHeaders">
                <p :key="key" class="font-bold">{{header}}</p>
            </template>
            <template #modal-footer>
                <div class="text-right">
                    <b-button @click="$bvModal.hide('missing-headers-modal')" variant="primary">Close</b-button>
                </div>
            </template>
        </b-modal>

        <vue-easy-lightbox escDisabled moveDisabled :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>
    </b-card>
</template>

<script>
import {
    getImgUrl,
    getProductionLineByIdUrl,
    orderFileUpload,
    productionLineDeleteUrl,
    productionLineDivideUrl,
    productionLineListUrl
} from '@routes';
import * as constants from '@constants';
import tableStateMixin from '../../mixins/tableState';
import { ProductionLineEntity } from '@/models/productionLineEntity';
import { extend } from 'vee-validate';
import { min_value, required, max_value } from 'vee-validate/dist/rules';
import Utilities from '@/js/utilities';
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import dayjs from 'dayjs';
import $permission from '@/js/permission';

extend('required', {
    ...required,
    message: 'Field is required'
});

extend('number', value => {
    if (Utilities.isNumeric(value)) {
        return true;
    }
    return 'Must be number';
});

extend('min_value', {
    ...min_value,
    message: 'Must be greater than 0'
});

extend('max_value', {
    ...max_value,
    message: 'Must be less than the Production line Kg'
});

extend('maxKg', {
    validate(value, { orderKg }) {
        let tolerance = Math.pow(10, -2);

        if (Number(value) < Number(orderKg) && Number(orderKg).toFixed(2) - Number(value).toFixed(2) > tolerance) {
            return true;
        }

        return 'Invalid value';
    },
    params: ['orderKg']
});

export default {
    components: {
        VueEasyLightbox
    },
    mixins: [tableStateMixin],
    data() {
        return {
            productionLineEntity: ProductionLineEntity.create(),
            productionLines: {
                fields: [
                    // { key: 'id', label: 'id', sortable: true },
                    { key: 'processStatus', label: '', sortable: false, thStyle: 'width: 2%;' },
                    { key: 'varietyName', label: 'Production line', class: 'text-center', sortable: true },
                    { key: 'compartmentName', label: 'Compartment', sortable: true, thStyle: 'width: 8%;' },
                    { key: 'stageTitle', label: 'Stage', sortable: true, thStyle: 'width: 8%;' },
                    { key: 'crop', label: 'Crop', sortable: true, thStyle: 'width: 9%;' },
                    { key: 'orderYear', label: 'Year', sortable: true, thStyle: 'width: 6%;' },
                    { key: 'season', label: 'Season', sortable: true, thStyle: 'width: 5%;' },
                    { key: 'sequence', label: 'Review', sortable: true, thStyle: 'width: 5%;' },
                    { key: 'parentalFemaleName', label: 'Female parent', sortable: true },
                    { key: 'parentalMaleName', label: 'Male parent', sortable: true },
                    { key: 'orderKg', label: 'Order Kg', sortable: true },
                    { key: 'yieldNl', label: 'Yield NL (g)', sortable: true },
                    { key: 'yieldMx', label: 'Yield MX (g)', sortable: true },
                    // { key: 'changeDate', label: 'Change date', sortable: true },
                    { key: 'orderDate', label: 'Order date', sortable: true },
                    { key: 'createdDate', label: 'Created date', sortable: true },
                    // { key: 'type', label: 'Type', sortable: true },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                filters: [
                    { value: '' },
                    { column: 'varietyName', type: 'STRING', value: '', format: '' },
                    { column: 'compartmentName', type: 'STRING', value: '', format: '' },
                    { column: 'stageTitle', type: 'STRING', value: '', format: '' },
                    { column: 'crop', type: 'STRING', value: '', format: '' },
                    { column: 'orderYear', type: 'STRING', value: '', format: '' },
                    { column: 'season', type: 'STRING', value: '', format: '' },
                    { column: 'sequence', type: 'STRING', value: '', format: '' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [10, 25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'varietyName',
                sortDesc: false,
                status: []
            },
            breadcrumb: {
                title: 'Production lines',
                path: []
            },
            toastTitle: 'Production lines',
            // orderFileModal: null,
            orderFile: null,
            validateFile: 'true',
            deleteData: 'false',
            validateReview: 'true',
            divideKg: null,
            calculateRemainingKg: '',
            productionLineSelected: null,
            remainingKg: 0,
            colorUnassigned: 'outline-primary',
            colorAssigned: 'outline-primary',
            colorFinished: 'outline-primary',
            colorCancelled: 'outline-primary',
            colorAll: 'outline-primary',
            duplicatedProductionLines: [],
            missingHeaders: [],
            urlImg: getImgUrl(),
            imgs: '',
            visible: false,
            index: 0,
            productionLinesUnassigned: true,
            productionLinesAssigned: false,
            productionLinesFinished: false,
            productionLinesCancelled: false,
            statusData: [],
            hasBulkLoadPermission: $permission.check('ROLE_BULO_C'),
            hasPermission: $permission.check('ROLE_PRLI_C')
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.productionLines, this.tableState);
        this.changeProductionLineStatus(1);
        this.changeProductionLineStatus(2);
        this.changeProductionLineStatus(3);
        this.changeProductionLineStatus(4);
    },
    methods: {
        loadData(productionLines) {
            if (productionLines.sortBy !== '') {
                let data = {
                    filters: this.productionLines.filters.filter(filter => filter.value.trim() !== ''),
                    ...productionLines
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;
                data.status = this.productionLines.status;

                return this.axios.post(productionLineListUrl(), data).then(response => {
                    if (response.data.code === 'OK') {
                        this.productionLines.totalRows = response.data.totalRows;
                        return response.data.productionLines;
                    } else {
                        return [];
                    }
                    /*if (response.data.code === constants.CODE_OK) {
                        this.productionLines.totalRows = response.data.totalRows;
                        return response.data.productionLines;
                    } else {
                        return [];
                    }*/
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                });
            }
        },
        tableContextChanged(context) {
            this.productionLines.sortBy = context.sortBy;
            this.productionLines.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.productionLines);
        },
        showModalFile() {
            this.$bvModal.show('order-files-modal');
        },
        saveOrderFile() {
            let formData = new FormData();
            formData.append('orderFile', this.orderFile);
            formData.append('validateFile', this.validateFile);
            formData.append('deleteData', this.deleteData);
            formData.append('validateReview', this.validateReview);

            this.axios.post(orderFileUpload(), formData).then(response => {
                if (response.data.status === 200) {
                    if (response.data.data.existFile) {
                        this.confirmReloadOrderFile();
                    } else if (response.data.data.majorReview) {
                        this.confirmLoadOrderFileReview();
                    } else if (response.data.data.missingHeaders) {
                        this.missingHeaders = response.data.data.missingHeaders;
                        this.$bvModal.show('missing-headers-modal');
                    } else {
                        if (response.data.data.length > 0) {
                            this.$bvModal.show('duplicated-production-lines-modal');
                            this.duplicatedProductionLines = response.data.data;
                        }

                        this.$bvToast.toast(response.data.message, {
                            title: this.toastTitle,
                            variant: 'success'
                        });
                        this.$refs.productionLineTable.refresh();

                        this.orderFile = null;
                        this.validateFile = 'true';
                        this.deleteData = 'false';
                        this.validateReview = 'true';
                        this.$bvModal.hide('order-files-modal');
                    }
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.validateFile = 'true';
                this.deleteData = 'false';
                this.validateReview = 'true';

            });
        },
        clearFileData() {
            this.orderFile = null;
            this.$bvModal.hide('order-files-modal');
        },
        confirmLoadOrderFileReview() {
            this.$swal({
                title: 'Warning',
                icon: 'warning',
                text: 'The file you are trying to upload is not the 1° review. Do you want to upload it anyway?',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.validateFile = 'false';
                    this.deleteData = 'false';
                    this.validateReview = 'false';
                    this.$bvModal.hide('order-files-modal');
                    this.saveOrderFile();
                }
            });
        },
        confirmReloadOrderFile() {
            this.$swal({
                title: 'Warning',
                text: 'The file you are trying to upload already exists. Do you want to upload it anyway?',
                icon: 'warning',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.validateFile = 'false';
                    this.deleteData = 'true';
                    this.validateReview = 'true';
                    this.$bvModal.hide('order-files-modal');
                    this.saveOrderFile();
                }
            });
        },
        deleteProductionLine(productionLine) {
            this.$swal({
                title: 'Delete production line',
                text: 'Are you sure to delete Production line ' + productionLine.varietyName +'?',
                icon: 'warning',
                iconColor: '#bf0d0d',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(productionLineDeleteUrl(), {
                        productionLineId: productionLine.id
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.productionLineTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            });
        },
        showDivideModal(productionLine) {
            this.getProductionLineById(productionLine.id);
        },
        divideProductionLine() {
            this.isLoading = true;

            this.axios.post(productionLineDivideUrl(), {
                id: this.productionLineSelected.id,
                orderKg: this.divideKg
            }).then(response => {
                if (response.status === 200) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    this.getProductionLineById(this.productionLineSelected.id);

                    if (this.$refs.formDivideProductionLine) {
                        requestAnimationFrame(() => {
                            this.$refs.formDivideProductionLine.reset();
                        });
                    }

                    this.$refs.productionLineTable.refresh();
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        changeProductionLineStatus(status) {
            if (status === 1) {
                this.productionLinesUnassigned = !this.productionLinesUnassigned;
                if (!this.productionLinesUnassigned) {
                    this.colorUnassigned = 'primary';
                } else {
                    this.colorUnassigned = 'outline-primary';
                }
            } else if (status === 2) {
                this.productionLinesAssigned = !this.productionLinesAssigned;
                if (this.productionLinesAssigned) {
                    this.colorAssigned = 'primary';
                } else {
                    this.colorAssigned = 'outline-primary';
                }
            } else if (status === 3) {
                this.productionLinesFinished = !this.productionLinesFinished;
                if (this.productionLinesFinished) {
                    this.colorFinished = 'primary';
                } else {
                    this.colorFinished = 'outline-primary';
                }
            } else if (status === 4) {
                this.productionLinesCancelled = !this.productionLinesCancelled;
                if (this.productionLinesCancelled) {
                    this.colorCancelled = 'primary';
                } else {
                    this.colorCancelled = 'outline-primary';
                }
            } else if (status === null) {
                this.colorAll = 'primary';
                this.colorUnassigned = 'outline-primary';
                this.colorAssigned = 'outline-primary';
                this.colorFinished = 'outline-primary';
                this.colorCancelled = 'outline-primary';
            }

            let index = this.statusData.indexOf(status);

            if (index >= 0) {
                this.statusData.splice(index, 1);
            } else {
                this.statusData.push(status);
            }

            this.productionLines.status = this.statusData.toString();
            this.$nextTick(() => {
                this.$refs.productionLineTable.refresh();
            });
            this.saveTableState(this.$route.name, this.productionLines);
        },
        getProductionLineById(id) {
            this.axios.get(getProductionLineByIdUrl(id)).then(response => {
                this.productionLineSelected = response.data.productionLine;
                this.divideKg = '';
                this.calculateRemainingKg = this.productionLineSelected.orderKg;
                this.remainingKg = this.productionLineSelected.orderKg;
            }).finally(() => {
                this.isLoading = false;
                this.$nextTick(() => {
                    this.$bvModal.show('divide-production-line-modal');
                });
            });
        },
        confirmCloseDuplicatedModal() {
            this.$swal({
                title: 'Warning',
                icon: 'warning',
                text: 'Are you sure to close this window?',
                customClass: {
                    container: 'swal-custom'
                },
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.$bvModal.hide('duplicated-production-lines-modal');
                }
            });
        },
        showImg(index, variety) {
            this.imgs = [
                {
                    src: this.urlImg + variety.fileId,
                    title: variety.varietyName + ' - ' + variety.figureName,
                    alt: variety.varietyName+ ' - ' + variety.figureName
                }
            ];

            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        formatDate(date) {
            let dateFormat;

            if (date) {
                dateFormat = dayjs(date.toString(), 'DD/MM/YYYY');
            }

            return date ? dateFormat.format('DD/MM/YYYY') : '';
        }
    },
    watch: {
        'productionLines.filters': {
            handler() {
                this.$refs.productionLineTable.refresh();
                this.saveTableState(this.$route.name, this.productionLines);
            },
            deep: true
        },
        divideKg() {
            if (isNaN(this.divideKg)) {
                return;
            }

            this.calculateRemainingKg = this.productionLineSelected.orderKg - this.divideKg;
        }
    },
    computed: {
        start() {
            return this.productionLines.totalRows === 0 ? 0 : (this.productionLines.currentPage - 1) * this.productionLines.itemsPerPage + 1;
        },
        end() {
            let offset = (this.productionLines.currentPage - 1) * this.productionLines.itemsPerPage;

            return (this.productionLines.totalRows - offset) < this.productionLines.itemsPerPage ? this.productionLines.totalRows : offset + this.productionLines.itemsPerPage;
        },
        setDivideErrorMessage() {
            let message = '';
            let orderKg = this.divideKg;

            if (!orderKg) {
                message = 'Field is required';
            } else if (!Utilities.isNumeric(orderKg)) {
                message = 'Must be number';
            } else if (!Utilities.isPositiveNumber(orderKg)) {
                message = 'Must be greater than 0';
            } else if (orderKg >= this.productionLineSelected.orderKg) {
                message = 'Invalid value';
            }

            return message;
        }
    }
};
</script>

<style>
.children-container {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px
}

.child-item {
    padding-bottom: 2px;
    font-size: 14px;
}

.child-item--main {
    font-weight: bold;
    display: inline-block;
    margin-right: 6px;
    width: 100px;
    text-align: left;
    padding-right: 12px
}

.child-item-kg--main {
    font-weight: bold;
    display: inline-block;
    width: 90px;
    text-align: right;
}

.no-bold {
    font-weight: lighter;
}

.main-divider {
    margin-bottom: 4px;
    width: 220px;
    border-bottom: 1px solid #ccc;
}

.swal2-container {
    z-index: 3000;
}
</style>